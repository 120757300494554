.drag-n-drop__dropzone {
  transition: opacity 250ms;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.85);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;
  background-image: url("data:image/svg+xml,<svg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'><rect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(0,0,0,0.45)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'><animate attributeName='stroke-dashoffset' values='0 2000' dur='5s' repeatCount='indefinite' /></rect></svg>");
  border-radius: 5px;

  &--hovering {
    &>span {
      opacity: 1;
      transform: scale(1.1);
    }
    & * {
      pointer-events: none;
    }
  }

  &>span {
    transition: .3s;
    text-align: center;
    opacity: 0.65;

    & .icon--plus {
      width: unset;
      height: unset;
    }

    &>p {
      font-size: 16px;
    }
  }
}