.entries {
  padding: 15px;

  & > .group-entry {
    margin-bottom: 15px;

    &:first-of-type {
      margin-top: 15px;
    }
  }

  & > span {
    margin-bottom: 15px;
  }

  & thead td {
    font-weight: 600;
  }

  & td {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  & tbody {
    border-top: none !important;

    & tr {
      cursor: pointer;
    }

    & td:not(:first-child) {
      vertical-align: middle;
      width: 1%;
    }
  }
}