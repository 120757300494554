@use "sass:math";

@function rem($size, $base: $base_font_size) {
  @if (type-of($size) == number) {
    @if (unit($size) != "px") {
      @error "`#{$size}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$size}` needs to be a number.";
  }

  @if (type-of($base) == number) {
    @if (unit($base) != "px") {
      @error "`#{$base}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$base}` needs to be a number.";
  }

  @return math.div($size, $base) * 1rem;
}

@function em($pixels, $context: 16px) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return math.div($pixels, $context) * 1em;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin icon {
  .icon > div, svg {
    @content;
  }
}

// MEDIA QUERIES

@mixin mq($minWidth, $maxWidth) {
  @media only screen and (min-width: em($minWidth)) and (max-width: em($maxWidth)) {
    @content;
  }
}

@mixin mq-max($maxWidth) {
  @media only screen and (max-width: em($maxWidth)) {
    @content;
  }
}

@mixin mq-min($minWidth) {
  @media only screen and (min-width: em($minWidth)) {
    @content;
  }
}

@mixin mq-min-h($minHeight) {
  @media only screen and (min-height: em($minHeight)) {
    @content;
  }
}

@function bk($bk: "mobile") {
  @if not map-has-key($breakpoints, $bk) {
    @warn "No breakpoint value found in $breakpoints map for '#{$bk}'. Property omitted.";
  }
  @return map-get($breakpoints, $bk);
}

// Shadows
@function shadow($shadow: "regular") {
  @if not map-has-key($shadows, $shadow) {
    @warn "No z-index found in $shadows map for '#{$shadow}'. Property omitted.";
  }
  @return map-get($shadows, $shadow);
}

// Z-Layers
@function z($layer: "base") {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for '#{$layer}'. Property omitted.";
  }
  @return map-get($z-layers, $layer);
}

// Easing values
@function ease($ease: "snappy") {
  @if not map-has-key($easing, $ease) {
    @warn "No easing value found in $easing map for '#{$ease}'. Property omitted.";
  }
  @return map-get($easing, $ease);
}