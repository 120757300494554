.new-planner__day {
  flex: 1;
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 120 120' version='1.1' xmlns='http://www.w3.org/2000/svg'><circle cx='60' cy='60' r='50' fill='rgba(0,0,0,0.3)'/><circle cx='60' cy='60' r='47' stroke='rgba(0,0,0,0.3)' stroke-width='3' stroke-dasharray='0 5' stroke-linecap='round' fill='transparent'/></svg>");
  background-position: right;
  background-size: 2px 12px;
  background-repeat: repeat-y;

  transition: background-color 0.2s ease-in-out;

  &--previous {
    background-color: rgba(140, 140, 140, 0.25);
    border-bottom: 1px solid rgba(0, 0, 0, 0.133) !important;
  }

  &--red-day {
    background-color: rgba(255, 97, 97, .25) !important;
  }

  &--previous-red-day {
    background-color: rgba(128, 48, 48, 0.25) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.133) !important;
  }

  &--before {
    position: relative;
    background-image: none;

    &::after {
      content: "";
      width: .25rem;
      background-color: #FF6161;
      border-radius: 1rem;
      position: absolute;
      top: .25rem;
      bottom: .25rem;
      right: 0;
      transform: translateX(50%);
    }
  }
}

.new-planner__month-header {
  height: 52px;
}
.new-planner__month-divider {
  position: absolute;
  width: 1px;
  height: 2rem;
  background-color: rgba(0,0,0,0.1);
  top: 50%;
  right: 0px;
  transform: translate(50%, -50%);
}
.new-planner__month-days-container {
  height: 42px;
}
.new-planner__month-day-block {
  transition: background-color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;

  &--monday {
    //border-left: 1px solid #e6bcbc;
  }
  &--red {
    border-bottom: 1px solid rgba(0, 0, 0, 0.133) !important;
  }
  &--fog-of-war {
    background-color: #D9D9D97F;
  }
}
.new-planner__month-day {
  transform: translate(-50%, 0);
  width: 1.75rem;
  height: 1.75rem;
  &--today {
    background-color: #FF6161;
    color: white;
  }
  &--holiday {
    border: 1px solid #FF6161;
  }
}
.new-planner__month-weekday {
  transform: translateX(-10%);
  font-size: 0.875rem;
  line-height: 1.25rem;
  opacity: .5;
}

.new-planner__bg-row-container {
  height: calc(100% - 96px);
}

.new-planner__container-container {
  position: relative;
  font-size: 1.5rem;
  height: calc(100vh - 50px - 28px);
  display: flex;
}

@keyframes sfonderino {
  from {
    background-color: rgba(255,255,255,0.5);
  }

  to {
    background-color: rgba(255,255,255,0.0);
  }
}

.new-planner__plan-entry {
  //border: 3px solid rgba(0,0,0,0.3);
  //border-radius: 1rem;
  transition: border-color 0.5s ease-in-out, opacity 0.2s ease-in-out;

  &--selected {
    animation: sfonderino 0.5s ease-in-out infinite alternate;
    -webkit-animation: sfonderino 0.5s ease-in-out infinite alternate;
    -moz-animation: sfonderino 0.5s ease-in-out infinite alternate;
    border-width: 3px !important;
  }
}

.new-planner__context-menu {
  position: absolute;
  z-index: 150;
  width: 300px;
  border: 1px solid #00000088;
  background-color: white;
}

.new-planner__floating-month {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  margin-left: 300px;
  height: 52px;
  z-index: 10;
  width: calc(100% - 300px)
}

.new-planner__plan-entry-text {
  font-size: 1.15rem;

  &--smaller {
    font-size: 1rem;
  }
}

.new-planner__user-choice-input {
  display: flex;

  &>*:first-child {
    flex-grow: 1;
  }

  & input {
    margin-right: .5rem;
  }
}

.new-planner__filter-button {
  display: flex !important;
  justify-content: center;
  align-items: center;

  & > .rounded-circle {
    width: 16px;
    height: 16px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    transition: .15s;
    background-color: var(--bs-dark) !important;
    color: var(--bs-white) !important;
  }

  &:hover {
    & > .rounded-circle {
      color: var(--bs-dark) !important;
      background-color: var(--bs-white) !important;
    }
  }
}
