
.dropdown-select__state-select {
  position: relative !important;
  --bs-bg-opacity: 0.1 !important;
  font-weight: bold;
}

span + .dropdown-select__state-select {
  padding-left: 1.75rem !important;
}

.dropdown-select__state-select-dot {
  position: absolute !important;
  display: inline !important;
  top: 11px !important;
  left: 9px !important;
}

.dropdown-select__status-select-chevron {
  left: auto !important;
  right: 7px !important;
  top: 2px !important;
  pointer-events: none;

  & > svg {
    width: 24px !important;
    height: 24px !important;
  }
}

@supports (-moz-appearance:none) {
  .dropdown-select__state-select-dot {
    top: 23px !important;
  }
  .dropdown-select__status-select-chevron {
    top: 2px !important;
    right: 6px !important;
  }
}

.dropdown-select__status-select-item {
  &:active {
    background-color: rgba(var(--bs-gray-rgb), 0.4) !important;
  }
}