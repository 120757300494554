.filter-bar{
  display: flex;
  align-items: center;
  width: fit-content;
  display: inline;
}

.calendar-div{
  display: inline;
}

.groupby-dropdown-button{
  margin: 10px 0 10px 10px;
}

.button-submit{
  margin: 0 10px;
}

div.list-option:hover{
  background-color: #0d6efd;
  color: white;
}

button.button-custom{
  margin: 0 5px;
}

.form_filter .form-check-label{
  width: 100%;
}

.form_filter .form-control{
  border: none;
}

.form_filter .list-group-item{
  padding: 5px 25px;
  font-size: larger;
  border: none;
}

.grouping-bar {
  display: flex;
  gap: 1rem;
}

div.histogram svg{
  width: 100%;
  height: 100%;
}

div.pie-chart{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 15px;
}

div.pie-chart svg{
  width: 100%;
  height: 100%;
}

div.primary-accordion-header .col,
div.primary-accordion-header .col-6{
  color:#0d6efd;
}

.sort-icon{
  display: inline-block;
  vertical-align: middle;
}

div.primary-accordion-header .sort-icon::before{
  content: "";
  display: inline-block;
  position: absolute;
  margin-left: 5px;
  padding-top: 1px;
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent
}

div.primary-accordion-header .sort-icon::after{
  content: "";
  display: inline-block;
  position: relative;
  margin-left: 5px;
  vertical-align: 1px;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent
}

div.primary-accordion-header .accordion-button:not(.collapsed){
  background-color: transparent;
}

div.accordion-circle{
  background-color: var(--bs-primary);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.statistics-filters {
  margin-top: 10px;
}

div.statistics-histogram__spinner-parent{
  width: 100%;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.statistics-entries__spinner-parent{
  display: flex;
  justify-content: center;
  align-items: center;
}

div.statistics-spinner{
  width: 30px;
  height: 30px;
}

div.statistics-calendar__date_range-popover{
  display: flex;
  max-width: 100%;
}

div.statistics-calendar__row{
  display: flex;
  align-items: center;
}

div.statistics-filters__popover{
  padding: 0;
}

.statistics-histogram__no-data {
  width: 1000px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0d6efd;
}

div.statistics-pie__container{
  display: flex;
  align-items: center;
  flex-direction: column;
}

div.statistics-pie__colored-row{
  color: #0d6efd
}

div.statistics-entries__name-col{
  display: inline;
  margin: 0 15px;
}

.list-group-secondary-item {
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,.05);
  }
}