.user-card.card {
  padding: 15px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  min-height: 61px;

  button {
    margin-left: auto;
  }
}