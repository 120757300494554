.reports {
  padding: 15px;

  hr {
    margin: 2rem 0;
  }
}

.reports__nav {
  display: flex;
  align-items: center;
  gap: 10px;

  & > *:not(:first-child):not(:last-child) {
    flex-grow: 1;
  }
}

@media (max-width: 680px) {
  .reports__nav {
    align-items: stretch;
    flex-direction: column;
  }
  .reports__nav .react-datepicker-wrapper input {
    text-align: left;
  }
}

.reports__new-report {
  font-weight: 600;
  min-width: 5vw;
}

.reports__filters {
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 600;
  margin-bottom: 2rem;

  & > span {
    min-width: 95px;
  }

  & > div {
    flex-grow: 1;
    position: relative;
  }
}

.reports__clear-icon {
  position: absolute;
  top: .5rem;
  right: .75rem;
}

.reports__group {
  margin-bottom: 15px;

  .card {
    padding: 15px;
  }
}

@media (hover: hover) {
  .report-card > .report-card__delete-button {
    display: none;
  }
  .report-card:hover > .report-card__delete-button {
    display: block;
  }
}