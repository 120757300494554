// COLORS
$white: #ffffff;

// FONTS
$base_font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
$base_font_size: 12px;

// BASE
$body_background: $white;
$text_color: black;

// BREAKPOINTS
$breakpoints: (
  phone: 320px,
  almost-phone: 400px,
  mobile-down: 540px,
  mobile: 541px,
  mobile-up: 640px,
  tab-down: 767px,
  tab: 768px,
  nav-down: 960px,
  desk-down: 1023px,
  desk: 1024px,
  large: 1280px,
  x-large: 1400px,
  xx-large: 1600px,
  fhd: 1920px,
  big-thing: 2200px,
  imac: 2560px
);

// CSS cubic-bezier timing functions.
// Refer to http://cubic-bezier.com/ or http://matthewlein.com/ceaser/ for more.
$easing: (
  in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
  in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
  out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
  out-expo: cubic-bezier(0.19, 1, 0.22, 1),
  out-circ: cubic-bezier(0.075, 0.82, 0.165, 1),
  out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
  in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),
  in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
  in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
  in-out-expo: cubic-bezier(1, 0, 0, 1),
  in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86),
  in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
  snappy: cubic-bezier(0.694, 0.048, 0.335, 1),
  power4: cubic-bezier(0.215, 0.61, 0.355, 1)
);

$shadows: (
  regular: 0px 9px 35px 0px rgba(64, 122, 145, 0.24),
  small: 0px 4px 30px 0px rgba(64, 122, 145, 0.14),
  light: 0px 2px 25px 0px rgba(64, 122, 145, 0.08)
);

// Z-Layers Map
$z-layers: (
  default: 1,
  behind: -1,
);