.icon {
  cursor: pointer;
}

.icon--ticket > div {
  display: flex;
}

.icon--x-lg > div, svg:not(.recharts-surface) {
  @include size(14px);
}

.nav-item > button {
  min-height: 2.5rem;
}

.is-admin {
  .tracker__tabbar {
    box-shadow: 0 4px 11px -5px rgba(64, 122, 145, 0.14);
    padding-bottom: 15px;
  }

  .current-entry__toolbar {
    box-shadow: none;
  }
}

input[type="time"] {
  text-align: center;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

img {
  @include size(30px);
  margin-right: 15px;
}

.accordion {
  ul {
    list-style: none;
    padding-inline-start: 0;
    padding-left: 0;
  }

  .col-md-4 {
    padding: 0 1.25rem 0 0;
  }
}

.customer {
  span {
    font-weight: bold;
  }

  padding-top: 1rem;
}

.project {
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.hoverable {
    cursor: pointer;
    &:hover {
      background-color: #f5f7fa;
    }
  }

  &.selected {
    background-color: #f5f7fa;
  }
}

.time-entry-editor__time-details {
  display: flex;
  gap: 15px;

  input {
    text-align: center;
  }

  & > div {
    width: 33.3%;
  }
}

.react-datepicker-wrapper {
  display: block !important;

  input {
    width: 100%;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 3px;
    padding: .375rem .75rem;
    font-size: 12px;
    text-align: center;
  }
}

.react-datepicker__day--selected {
  background-color: var(--bs-primary);
}

.accordion-header {
  .accordion-button {
    &::after {
      display: none;
    }
  }
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0,0,0,.125) !important;
}



.groups {
  padding: 15px;
  margin-bottom: 15px;

  & > div {
    margin-top: 15px;
  }
}

.is-dirty {
  border-color: #e7f1ff !important;
}

.customers-suggestions {
  &.popover .popover-arrow {
    display: none;
  }
}

.project-editor {
  .form-label {
    text-transform: uppercase;
    font-weight: 600;
  }

  .row > div:first-of-type {
    padding-left: 0;
  }

  .row > div {
    padding-right: 0;
  }

  .spinner {
    text-align: center;
    width: 100%;

    .spinner-border {
      margin: 10px 0;
      border-width: 0.1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .budget .input-group-text {
    min-width: 80px;
  }

  .accordion-header button {
    height: 29px;
  }
  form > div:not(:first-child) {
    margin-top: 15px;
  }
}

.list {
  padding-bottom: 15px;

  .card {
    padding: 15px;
  }

  & > div {
    margin-top: 15px;
  }
}

.project__actions {
  margin-left: auto;

  .icon {
    display: inline-block;
    margin-left: 15px;
    border: 1px solid;
    border-radius: 3px;
    padding: 5px 6px;
    cursor: pointer;
  }

  .icon--pencil-fill {
    border-color: var(--bs-black);
  }

  .icon--trash-fill {
    svg * {
      fill: var(--bs-danger);
    }
    border-color: var(--bs-danger);
  }
}

.project-list__project.card {
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  button {
    margin-left: auto;
    min-width: max-content;
  }
}

.project__tasks {
  margin-top: 5px;
  font-size: 11px;
}

.project__colored-dot, .project-colored-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  min-width: 8px;
  display: inline-block;
  margin-right: 5px;
}

.list-toolbar {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0;

  button {
    min-width: max-content; // 92px; // 9vw;
  }

  .spinner-border {
    margin-left: -0.5rem;
  }

  label {
    white-space: nowrap;
  }
}

.login {
  padding: 15px 0;

  & > div {
    margin-bottom: 15px;
  }
}

.icon--plus, .icon--x-lg, .icon--trash-fill > div, svg:not(.recharts-surface) {
  @include size(16px);
}

.popover.hide-border {
  border: none;
  background: transparent;
}

.popover.user-popover {
  .popover-arrow {
    display: none;
  }

  overflow-y: scroll;
  min-width: 250px;
  max-height: 150px;
  font-size: 12px;
  padding: 10px;
}

.loading-pane {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.125s ease-in-out;

  &.visible {
    opacity: 1
  }

  &:not(.visible) {
    pointer-events: none;
  }

  img {
    @include size(30vh);
    user-select: none;
  }
}

.modal .idle-modal {
  max-width: 350px;
  text-align: center;
  padding: 25px;

  .modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.idle-modal__actions {
  button {
    margin-bottom: 5px;
    width: 100%;
  }
}

.idle-modal__title {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.idle-modal__duration {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.dropdown-divider {
  border-top: none !important;
}

.customer-group {
  margin-bottom: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-grab {
  cursor: grab;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-help {
  cursor: help;
}

.input__disabled-lighter {
  background-color: #f5f7fa !important;
}

.support-plans__wrapper > .card {
  margin-bottom: 7.5px;
}

.support-plan {
  padding: 7.5px;

  & > div:not(:last-child) {
    margin-bottom: 7.5px;
  }
}

.pluto-enabled {
  display: flex;

  & > div {
    margin-right: 5px;
  }
}

.input__side-button {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 38px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.iconfix {
  vertical-align: text-bottom !important;

  &--translate1 {
    transform: translateY(1px);
  }

  &--translate2 {
    transform: translateY(2px);
  }

  &--plus {
    width: 12px;
    height: 12px;
    transform: translateY(-1px);
  }

  &--large {
    width: 18px !important;
    height: 18px !important;
  }

  &--chevron {
    transform: translate(1px, 2px);
  }
}

.accordion__chevron {
  transform: translateX(1px);
  & > * {
    vertical-align: text-bottom !important;
  }
  transition: transform 0.3s ease-out;
}

.accordion-button.collapsed > .accordion__chevron {
  transform: translateX(1px) rotate(180deg) !important;
}

.project-editor .accordion-header[draggable="false"] .accordion-button {
  cursor: grab !important;
}

@media (min-width: 576px) {
  .project-editor .modal-dialog {
    max-width: 500px !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
}

@media (min-width: 612px) {
  .project-editor .modal-dialog {
      max-width: 500px !important;
      margin-left: auto !important;
      margin-right: auto !important;
  }
}

.project__disabled-task {
  padding: 1px 3px;
}

.project__tasks-notes-editor {
  min-height: 140px !important;
}
.project__tasks-notes-editor-button {
  top: 1rem;
  right: 1rem;
}

.project__tasks-notes-rendered > *:last-child {
  margin-bottom: 0;
}

.all-child-cursor-pointer {
  & > * {
    cursor: pointer !important;
  }
}

@each $side in top, bottom {
  .rounded-#{$side}-0 {
    border-#{$side}-left-radius: 0 !important;
    border-#{$side}-right-radius: 0 !important;
  }
}

.mw-fit-content {
  min-width: fit-content;
}
.mw-max-content {
  min-width: max-content;
}
.mfa-code {
  border: 1px solid #ced4da;
  border-radius: 3px;
  padding: 5px;
}

.error-boundary {
  img {
    height: auto;
    width: auto;
  }

  h1 {
    margin-top: 20px;
    font-size: 4rem;
    margin-bottom: 15px;
  }

  button {
    margin-top: 15px;
    font-size: 1.2rem;
  }
}

.align-sub {
  vertical-align: sub;
}

input:disabled, input:read-only:not([type="checkbox"]), textarea:read-only, select:disabled {
  background-color: #f5f7fa !important;
}

.disabled-white {
  background-color: white !important;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}

.shake {
  animation: shake 0.2s ease-in-out 0s 2;
}

.aws-account-form__dropdowns > div:not(:last-child) {
  margin-bottom: 0.5rem;
}

.aws-account-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.user-form__table {
  margin-bottom: 0 !important;

  & > tbody > tr > td {
    width: 20%;
  }
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  & > span {
    position: relative;
    display: inline-block;
  }

  & > span:before,
  & > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #0004;
  }

  & > span:before {
    right: 100%;
    margin-right: 15px;
  }

  & > span:after {
    left: 100%;
    margin-left: 15px;
  }
}

:root {
  --bs-orange-rgb: 253, 126, 20;
}

.text-orange {
  color: var(--bs-orange) !important;
}

.bg-orange {
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity, 1)) !important;
}

.pointer-events-none {
  pointer-events: none;
}

.max-content-width {
  max-width: 1024px;
  position: relative;
  margin: auto;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  &--planner {
    max-width: 1240px;
  }
}

.dropdown-select__state-select {
  position: relative !important;
  --bs-bg-opacity: 0.1 !important;
  font-weight: bold;
}

span + .dropdown-select__state-select {
  padding-left: 1.75rem !important;
}

.dropdown-select__state-select-dot {
  position: absolute !important;
  display: inline !important;
  top: 11px !important;
  left: 9px !important;
}

.dropdown-select__status-select-chevron {
  left: auto !important;
  right: 7px !important;
  top: 2px !important;
  pointer-events: none;

  & > svg {
    width: 24px !important;
    height: 24px !important;
  }
}

@supports (-moz-appearance:none) {
  .dropdown-select__state-select-dot {
    top: 23px !important;
  }
  .dropdown-select__status-select-chevron {
    top: 2px !important;
    right: 6px !important;
  }
}

.overflow-unset {
  overflow: unset !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.light-gradient-bg {
  background: linear-gradient(150deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 50%);
}

.customer-form__child-customer-dropdown-group > span {
  flex-grow: 1;
}

*:focus {
  outline: none;
}

.fw-semibold {
  font-weight: 550;
}

.min-h-100 {
  min-height: 100%;
}

.select-none {
  user-select: none;
}

.z-1 {
  z-index: 1;
}

.opaque-on-hover-50 {
  opacity: 0.50;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.icon--plus-slash-minus {
  height: 14px !important;
  width: 14px !important;

  & svg {
    height: 14px !important;
    width: 14px !important;
  }
}
