.header__side {
  display: flex;
  margin-left: auto;
  gap: 15px;

  .dropdown-toggle::after {
    margin-left: 0.5em;
    vertical-align: 0.15em;
  }

  .dropdown-item:active {
    color: white;
  }

  a, a:hover {
    color: var(--bs-black);
    text-decoration: none;
  }
}