.lead-list {

  &__lead {
    margin-top: 15px;
    flex-direction: column;
    align-items: left;
    cursor: pointer;
    font-size: 11px;

    .tag {
      font-size: 11px !important;
      margin: 0px;
      margin-left: 9px;
    }
  }

  &__row {
    &:first-of-type {
      font-size: 15px;
    }

    gap: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &__compact {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

  }

}

.lead-editor {
  .modal-content {
    max-height: 90% !important;
  }

  .form-label {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.leads-form__side::-webkit-scrollbar{
  display: none !important;
}

@media (min-width: 992px) {
  .lead-editor {
    .modal-body {
      height: 90vh;
      padding-bottom: 0;
      padding-top: 0;
      overflow-y: auto;
    }
  }
}

.lead-list__customer.card {
  margin-top: 15px;
}

.lead-status {
  border-radius: 3px;
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7.5px;
}

.tag {
  border-radius: 0.25rem;
  font-size: 1rem !important;
  padding: 0.25rem 0.5rem;
  border: 1px solid #212529;
  display: flex;
  align-items: center;
  gap: 6px;
  width: min-content;
  cursor: pointer;
  transition: all 0.125s ease-in-out;

  &--disabled {
    border-color: #bbc2c7;
    color: #bbc2c7;
  }
}

.call-date-picker {
  border-left: none !important;
  border-radius: 0 !important;
  &:focus {
    outline: none;
  }
}

.delete-button {
  margin-left: auto;
  margin-right: 15px !important;
}

.lead-list-toolbar {
  display: flex;
  align-items: center;
  gap: 6px;

  button {
    min-width: max-content; // 92px; // 9vw;
  }

  .spinner-border {
    margin-left: -0.5rem;
  }

  label {
    white-space: nowrap;
  }

  .search-bar {
    min-width: 200px;
    flex: 1;
    // max-width: 500px;
  }

  .clear-filter-button {
    height: 29px;
    padding-top: 5.5px;
    padding-bottom: 5.5px;

    &__disabled {
      border-color: var(--bs-gray);
    }
  }
}