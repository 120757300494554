.project-searchbox__wrapper {
  overflow: scroll;
  min-width: 300px;
  max-height: 300px;
  padding: 15px;
  transition: none !important;

  & > * {
    font-size: 1rem;
  }

  & > input.shift {
    margin-bottom: 15px;
  }
}