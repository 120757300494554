.search-dropdown {
  box-shadow: 0 4px 30px 0 rgba(64, 122, 145, 0.14);
  position: absolute;
  top: 29px;
  z-index: 2000;
  background-color: white;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,.125);
  max-height: 200px;
  overflow-y: scroll;
  transition: opacity 0ms !important;

  .project:first-child {
    margin: 0;
  }
}

.project-form__price-per-hour {
  max-width: 75px;
}

.project-form__color-picker {
  max-width: 48px;
}

.send-reports label {
  text-transform: uppercase;
  font-weight: 600;
}

.project-form__task-section {
  margin-top: 5px;

  .input-group-text {
    min-width: 80px;
  }
}