.bottom-bar {
  position: fixed;
  z-index: 10;
  bottom: 0 !important;
  left: 0;
  right: 0;
  min-height: 24px;
  width: 100vw;
  //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  //background-color: var(--bs-danger);
  border-top: 1px solid var(--bs-gray-300);
  background-color: var(--bs-light);
}

.bottom-bar__contents {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  gap: .5rem;

  & > span:not(:last-child) {
    padding-right: .5rem;
    border-right: 1px solid var(--bs-gray-400);
  }
}

.bottom-bar__placeholder {
  height: 24px;
}