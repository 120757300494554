.time-entries {
  margin: 15px 0;
}

.time-entries__day {
  padding: 15px;
  margin-bottom: 15px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }

  .time-entries__daily-duration {
    float: right;
    margin-right: calc(2.5rem + 5px);
  }

  .accordion-header {
    line-height: 18px;
    min-height: 65px;
    display: flex;
  }

  .accordion-body .group-entry {
    &:first-child {
      margin-top: -1rem;
    }
    &:last-child {
      margin-bottom: -1rem;
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0,0,0,0.125);
    }
  }
}

.group-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 67px;
  cursor: pointer;
  gap: 1rem;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(.stack) {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 1rem 1.25rem;
  }
}

.group-entry:hover, .time-entries__day .accordion-header:hover {
  .icon--play-fill {
    visibility: visible;
    pointer-events: all;
  }
}

.group-entry-user {
  margin-left: auto;
}

.group-entry-duration {
  margin-left: auto;
  display: flex;
  align-items: center;

  .icon--play-fill {
    padding-left: 5px;
    margin-right: -0.5rem;
    visibility: hidden;
    pointer-events: none;

    & > div, svg {
      @include size(20px);
    }
  }
}

.group-entry__count {
  background-color: var(--bs-primary);
  color: white;
  border-radius: 50%;
  @include size(24px);
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-entry__description {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}