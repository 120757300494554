.time-entry-form {
  .form-label {
    text-transform: uppercase;
    font-weight: 600;
  }

  form > div:not(:first-child) {
    margin-top: 15px;
  }

  .modal-footer {
    .btn-danger {
      margin-right: auto;
    }
  }

  .react-datepicker__input-container input {
    height: 29px;
  }

  .icon--arrow-right-short {
    margin-top: 2rem;
    cursor: auto;
  }
}

@include mq-min(576px) {
  .time-entry-form .modal-dialog {
    max-width: 350px;
  }
}

.time-entry-form__duration-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-control.is-invalid {
    background: none;
    padding-right: 0.75rem;
  }
}

.time-entry-form__start, .time-entry-form__stop, .time-entry-form__duration {
  min-width: 90px;
}

.time-entry-form__duration .form-control:last-child {
  text-align: center;
  width: 90px;
}

.icon--arrow-right-short {
  & > div, svg {
    @include size(20px);
  }
}

.time-entry-form__details {
  position: absolute;
  pointer-events: none;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
}

.time-entry-form__price-per-hour {
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    text-align: center;
  }

  &--warning {
    height: 16px;
  }
}