// Required styles
@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

.planner__page-container {
  --planner-width: calc(var(--planner-block-width) * var(--planner-columns));
  --planner-user-height: calc(var(--planner-block-height) * var(--planner-rows));
  --planner-height: calc(var(--planner-block-height) * var(--planner-total-rows));
}

.planner__container {
  display: flex;
  width: var(--planner-width);
}

.planner__user-name {
  height: calc(var(--planner-user-height) - 50px);
  width: var(--planner-user-column-width);
  overflow-y: hidden;

  position: relative;

  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  padding-top: 10px;
  margin-bottom: var(--planner-block-height);

  & > div {
    overflow-y: auto;
    height: 100%;
  }

  & > div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  & > div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.planner__grid {
  margin-right: 0;

  &--noinput {
    pointer-events: none;
  }

  &--loading {
    pointer-events: none;

    & .planner__time-entry:not(.planner__time-entry--weekend):not(.planner__time-entry--ghost) {
      transition: opacity .15s;
      opacity: 0.5;
    }
  }

  & > div {
    max-width: var(--planner-width);
  }
}

.planner__grid-hor-background {
  background-image: linear-gradient(to right, #fff 5px, transparent 1px),
  linear-gradient(#ccc 1px, transparent 1px);
  background-size: 15px var(--planner-block-height);
}

.planner__grid-vert-background {
  background: linear-gradient(to right, transparent 99%, var(--bs-gray) 1%);
  background-size: calc(961px/7);
}

.planner__grid-weekend-background {
  width: calc(2 * var(--planner-block-width)) !important;
  left: calc(var(--planner-user-column-width) + 1rem + 5 * var(--planner-block-width));
  opacity: 0.25;
  background-color: red;
}

@for $i from 0 through 6 {
  .planner__grid-holiday-background[data-day="#{$i}"] {
    --day: #{$i};
  }
}
.planner__grid-holiday-background {
  width: calc(1 * var(--planner-block-width)) !important;
  opacity: 0.25;
  background-color: red;
  left: calc(var(--planner-user-column-width) + 1rem + var(--day) * var(--planner-block-width));
}

.planner__grid > * {
  position: absolute;
  height: 100%;
  width: 100%;
  max-height: var(--planner-height);
}

.planner__time-entry {
  border: 2px solid rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #feccaa;
  border-radius: 4px;
  cursor: pointer;

  &-content {
    margin: 5px;
    font-size: 12px;

    &--small {
      font-size: 10px;
    }
  }

  &--weekend {
    background: repeating-linear-gradient(
      -55deg,
      var(--bs-gray),
      var(--bs-gray) 10px,
      var(--bs-gray-dark) 10px,
      var(--bs-gray-dark) 20px
    );
    color: var(--bs-black);
    cursor: not-allowed;
  }

  &--ghost {
    opacity: 0.5;
    border-style: dashed;
    background-color: #cccccc;
  }

  &--selected {
    border-color: #0009;
  }
}

.planner__days {
  width: 100%;
  z-index: 10;
  background-color: white;
  height: 54px;

  &--sticky {
    position: fixed;
    top: 0;
  }

  & > div {
    margin-left: var(--planner-user-column-width);
    width: calc(var(--planner-block-width) * 7 + 2px);

    & > span {
      text-align: center;
      z-index: 12;
      width: calc(1/7 * 100%);
      font-size: calc(var(--bs-body-font-size)*1.2);
      padding-top: 5px;
      padding-bottom: 5px;
      display: inline-block;

      &:not(:last-child) {
        border-right: 1.5px solid var(--bs-gray);
        transform: translateX(0.3px);
      }
    }
  }
}

.planner__days-placeholder {
  display: block;
  position: relative;
  margin-left: var(--planner-user-column-width);
  height: 54px;
  width: calc(var(--planner-block-width) * 7 + 2px);
}

.planner__user-customer-view {
  overflow-y: scroll;
  font-size: 13.8px;

  & hr {
    margin: .75rem 0 !important;
  }
}

.user-filter__wrapper {
  display: flex;
  gap: 1rem;

  button {
    height: 29px;
  }

  & > span {
    flex-grow: 1;
  }
}

.user-filter__users {
  margin: 0 -.5rem .5rem 0;
  gap: 1rem;
  transition: opacity 0.125s ease-in-out;

  & > div {
    margin: 0 .5rem .5rem 0;
    border-radius: 0.25rem;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    gap: 0.375rem;
    border: 1px solid rgb(206, 212, 218);
    padding: .375rem 0.75rem;

    svg {
      cursor: pointer;
    }
  }
}

.planner__global-customers-draggable {
  box-shadow: 0 4px 30px 0 rgba(64, 122, 145, 0.14);
}

.planner__global-customers {
  border-color: rgba(0,0,0,.5);

  & .planner__global-customers-contents {
    position: relative!important;
    max-height: 200px;
    overflow: scroll;
    background-color: var(--bs-light);
  }

  & hr {
    z-index: 1;
  }

  &--pinned {
    min-height: unset !important;
    max-height: 75vh;

    z-index: 20;

    &  .planner__global-customers-contents {
      max-height: max-content;
      margin-bottom: 0 !important;

      overflow: scroll;
      position: absolute !important;
      width: 100%;
    }

    & .planner__global-customers-search-row {
      margin-top: 24px;
    }
  }
}

.planner__global-customers-list {
  height: 100%;
  max-height: 100%;
}

.planner__global-customers-drag {
  background-color: rgba(0,0,0,0.1);
  color: black;
  height: 24px;
  margin: -1rem;
  margin-bottom: 1rem;
  position: absolute;
}

.planner__global-customers-search-row {
  width: calc(100% - 1rem);
}

.planner__global-customers-header {
  padding: 1rem;
  position: absolute;
  width: 100%;
  z-index: 1;
}
