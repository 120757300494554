.tickets-modal {
  & .form-control:disabled, & .form-control[readonly] {
    //background-color: #fff !important;
    opacity: 1;
  }
}

.tickets-modal__body {
  height: 80vh;
}

.tickets-modal__opaque-sheet {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: white;
  opacity: 0;
  z-index: 1000;
  visibility: hidden;

  transition: 0.1s ease-in-out;

  &--active {
    opacity: 0.5;
    visibility: visible;
  }
}

.tickets-modal__text-editor {
  & > .project__tasks-notes-editor {
    height: 100%;
    resize: none;
  }
}

.tickets-modal__tag-icon {
  transform: translateY(3px);
}

.tickets-modal__attachment-size {
  max-width: 92px;
}

.tickets-modal__message {
  &--private {
    background-color: rgba(var(--bs-dark-rgb), 0.05) !important;
  }

  &--customer {
    background-color: rgba(var(--bs-info-rgb), 0.15) !important;
  }

  &--staff {
    background-color: white !important
  }
}

.tickets-modal__backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.tickets-modal__state-select {
  position: relative !important;
  --bs-bg-opacity: 0.1 !important;
  padding-left: 1.75rem !important;
  font-weight: bold;
}

.tickets-modal__state-select-dot {
  position: absolute !important;
  display: inline !important;
  top: 11px !important;
  left: 9px !important;
}

.tickets-modal__status-select-chevron {
  left: auto !important;
  right: 7px !important;
  top: 2px !important;
  pointer-events: none;

  & > svg {
    width: 24px !important;
    height: 24px !important;
  }
}

.tickets-modal__priority-select-triangle {
  left: 8px;
  top: 6px;
}

.tickets-modal__priority-select-dot {
  left: 9px;
  top: 11px;
}

.tickets-modal__status-select-item {
  &:active {
    background-color: rgba(var(--bs-gray-rgb), 0.4) !important;
  }
}

/* Sometimes, I hate Firefox. */
@supports (-moz-appearance:none) {
  .tickets-modal__state-select-dot {
    top: 23px !important;
  }
  .tickets-modal__status-select-chevron {
    top: 2px !important;
    right: 6px !important;
  }
}


.tickets-new-modal__customer-picker {
  & > button {
    width: 100%;
  }
}

.tickets-new-modal__rest-of-form {
  opacity: 0.75;
  pointer-events: none;
}

.tickets-modal__next-status-item {
  padding: .5rem .75rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: .25rem;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: .25rem;
  }

  &--selected {
    border-color: rgba(var(--bs-dark-rgb), 0.15) !important;
    background-color: rgba(var(--bs-dark-rgb), 0.05) !important;
    box-shadow: 0 0 .3rem .1rem rgba(var(--bs-dark-rgb), 0.05) !important;
  }

  &:hover {
    border-color: rgba(var(--bs-dark-rgb), 0.1);
    background-color: rgba(var(--bs-dark-rgb), 0.05) !important;
    box-shadow: none;
  }
}

.tickets-modal__alignment-area {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  & > label {
    padding-top: .25rem;
  }
}

.tickets-modal__question-mark {
  scale: 1.2;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}