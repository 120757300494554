* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: $base_font_size;
  font-family: $base_font;
}

body {
  background: $body-background;
  color: $text-color;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Selection colors
/*
::selection,
::-webkit-selection {
  background: $selection-bg;
  color: $selection-color;
  text-shadow: none;
}
*/

img::selection {
  background: transparent;
}

main,
section {
  width: 100%;
  position: relative;
}

main {
  height: 100vh;
}

// MEDIA
img,
picture,
video,
audio,
embed,
object,
input,
iframe {
  max-width: 100%;
  margin: 0;
}

img {
  display: inline-block;

  a:hover & {
    border: none;
    background: none;
  }
}

x::-ms-reveal,
img[src*='.svg'] {
  width: 100%;
}

a img {
  border: none;
}

// TYPOGRAPHY
a {
  color: inherit;
}

b, strong {
  font-weight: 600;
}

svg {
  vertical-align: baseline !important;
}

.modal-dialog-scrollable .modal-content {
  max-height: 80% !important;
}

.btn {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.react-datepicker__input-container input {
  height: 29px;
}