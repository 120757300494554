// Config
@import "config/config";
@import "config/tools";

// Base
@import "base/normalize";
@import "base/base";
@import "base/typography";

// @tailwind base;
@tailwind components;
@tailwind utilities;

// Pages
@import "pages/tracker";
@import "pages/not-found";
@import "pages/reports";
@import "pages/statistics";
@import "pages/planner";
@import "pages/new-planner";
@import "pages/entries";
@import "pages/tickets";
@import "pages/leads";

// Components
@import "components/current-entry";
@import "components/project-searchbox";
@import "components/project-form";
@import "components/time-entry-form";
@import "components/time-entries";
@import "components/header";
@import "components/dropdown-select";
@import "components/bottom-bar";
@import "components/drag-n-drop";
@import "../../node_modules/react-grid-layout/css/styles.css";
@import "@szhsin/react-menu/dist/index.css";
@import "@szhsin/react-menu/dist/theme-dark.css";
@import "@szhsin/react-menu/dist/transitions/slide.css";

// Shame
@import "shame";