.current-entry__wrapper {
  display: flex;
  padding: 15px;
  gap: 15px;

  .icon--play-circle-fill svg * {
    fill: var(--bs-primary);
  }

  .icon--stop-circle-fill svg * {
    fill: var(--bs-danger);
  }

  .icon--play-circle-fill, .icon--stop-circle-fill {
    & > div, svg {
      @include size(32px);
      transition: all 0.125s ease;
    }
  }
}

#current-entry-duration {
  width: 85px;
  text-align: center;
}

.current-entry__toolbar {
  padding: 0 15px 15px 15px;
  min-height: 43px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 11px -5px rgba(64, 122, 145, 0.14);

  .icon--folder {
    @include size(16px);
  }
}

.current-entry__selected-project {
  cursor: pointer;
  background-color: #f5f7fa;
  border-radius: 3px;
  padding: 5px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 7.5px;

  &--hoverable {
    transition: background-color .25s ease;
    &:hover {
      background-color: #ebedf0;
    }
  }
}

.current-entry__duration-editor {
  padding: 15px;
  margin-top: 5px;

  input[type="time"] {
    margin-bottom: 15px;
  }

  .form-label {
    text-transform: uppercase;
  }
}

.current-entry__selected-project-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover .icon--x-lg {
    opacity: 1;
    pointer-events: all;
  }

  .project-colored-dot {
    margin-right: 0;
  }

  .icon--x-lg {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.125s ease-in-out;
    & > div, svg {
      @include size(16px);
    }
  }
}

.current-entry__warning {
  height: 16px;
}